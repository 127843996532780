@import './components/vars';

$primary: $colorPrimary;

$bootstrapBlue: #007bff;
$link-color: $bootstrapBlue;

@import '~bootstrap/scss/bootstrap';


@import './components/index';
