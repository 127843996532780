@import '../../../../scss/components/vars.scss';
@import '../../../../scss/components/mixins.scss';

.reservation-modal.modal-dialog {
  @include sm {
    max-width: 600px;
  }

  @include md {
    max-width: 800px;
  }
}
