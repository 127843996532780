@import '../../../scss/components/vars.scss';
@import '../../../scss/components/mixins.scss';
@import '../../../scss/components/utilities.scss';

@mixin strikethrough($color: black, $angle: -5deg) {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid $color;
    border-color: inherit;
    transform: rotate($angle);
  }
}

.product-card {
  margin: 0 5px 10px;
  max-width: 350px;

  .card-header {
    font-size: 1rem;
    font-weight: bold;
    color: black;
  }

  .card-body {
    &:empty {
      padding: 0 !important;
    }
  }

  textarea {
    min-width: 300px;
  }

  .form-control {
    margin-bottom: 0.5rem;
  }

  .price {
    &--old {
      color: $colorDanger;
      @include strikethrough($colorDanger, -15deg);
    }
  }

  &--invalid {
    @extend .b-danger;

    input[name='price'] {
      border: 1px solid $colorDanger !important;
    }

    .price {
      color: $colorDanger;
      font-weight: bold;
    }
  }

  img {
    margin-top: 10px;
    max-height: 150px;
    max-width: 100%;
  }
}
