.hint,[data-hint] {
  position: relative;
  display: inline-block
}

.hint:before,.hint:after,[data-hint]:before,[data-hint]:after {
  position: absolute;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  -webkit-transition: .3s ease;
  transition: .3s ease;
  -webkit-transition-delay: 0;
  transition-delay: 0
}

.hint:hover:before,.hint:hover:after,.hint:focus:before,.hint:focus:after,[data-hint]:hover:before,[data-hint]:hover:after,[data-hint]:focus:before,[data-hint]:focus:after {
  visibility: visible;
  opacity: 1
}

.hint:hover:before,.hint:hover:after,[data-hint]:hover:before,[data-hint]:hover:after {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms
}

.hint:before,[data-hint]:before {
  content: '';
  position: absolute;
  background: 0 0;
  border: 6px solid transparent;
  z-index: 1000001
}

.hint:after,[data-hint]:after {
  content: attr(data-hint);
  background: #383838;
  color: #fff;
  padding: 8px 10px;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap
}

.hint--top:before {
  border-top-color: #383838
}

.hint--bottom:before {
  border-bottom-color: #383838
}

.hint--left:before {
  border-left-color: #383838
}

.hint--right:before {
  border-right-color: #383838
}

.hint--top:before {
  margin-bottom: -12px
}

.hint--top:after {
  margin-left: -18px
}

.hint--top:before,.hint--top:after {
  bottom: 100%
}

.hint--top:hover:after,.hint--top:hover:before,.hint--top:focus:after,.hint--top:focus:before {
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px)
}

.hint--bottom:before {
  margin-top: -12px
}

.hint--bottom:after {
  margin-left: -18px
}

.hint--bottom:before,.hint--bottom:after {
  top: 100%
}

.hint--bottom:hover:after,.hint--bottom:hover:before,.hint--bottom:focus:after,.hint--bottom:focus:before {
  -webkit-transform: translateY(8px);
  -ms-transform: translateY(8px);
  transform: translateY(8px)
}

.hint--right:before {
  margin-left: -12px;
  margin-bottom: -6px
}

.hint--right:after {
  margin-bottom: -14px
}

.hint--right:before,.hint--right:after {
  left: 100%;
  bottom: 50%
}

.hint--right:hover:after,.hint--right:hover:before,.hint--right:focus:after,.hint--right:focus:before {
  -webkit-transform: translateX(8px);
  -ms-transform: translateX(8px);
  transform: translateX(8px)
}

.hint--left:before {
  margin-right: -12px;
  margin-bottom: -6px
}

.hint--left:after {
  margin-bottom: -14px
}

.hint--left:before,.hint--left:after {
  right: 100%;
  bottom: 50%
}

.hint--left:hover:after,.hint--left:hover:before,.hint--left:focus:after,.hint--left:focus:before {
  -webkit-transform: translateX(-8px);
  -ms-transform: translateX(-8px);
  transform: translateX(-8px)
}

.hint:after,[data-hint]:after {
  text-shadow: 0 -1px 0 #000;
  box-shadow: 4px 4px 8px rgba(0,0,0,.3)
}

.hint--error:after {
  background-color: #b34e4d;
  text-shadow: 0 -1px 0 #592726
}

.hint--warning:after {
  background-color: #c09854;
  text-shadow: 0 -1px 0 #6c5328
}

.hint--info:after {
  background-color: #3986ac;
  text-shadow: 0 -1px 0 #193b4d
}

.hint--success:after {
  background-color: #458746;
  text-shadow: 0 -1px 0 #1a321a
}

.hint--always:after,.hint--always:before {
  opacity: 1;
  visibility: visible
}

.hint--always.hint--top:after,.hint--always.hint--top:before {
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px)
}

.hint--always.hint--bottom:after,.hint--always.hint--bottom:before {
  -webkit-transform: translateY(8px);
  -ms-transform: translateY(8px);
  transform: translateY(8px)
}

.hint--always.hint--left:after,.hint--always.hint--left:before {
  -webkit-transform: translateX(-8px);
  -ms-transform: translateX(-8px);
  transform: translateX(-8px)
}

.hint--always.hint--right:after,.hint--always.hint--right:before {
  -webkit-transform: translateX(8px);
  -ms-transform: translateX(8px);
  transform: translateX(8px)
}

.hint--rounded:after {
  border-radius: 4px
}

.hint--no-animate:before,.hint--no-animate:after {
  -webkit-transition-duration: 0;
  transition-duration: 0
}

.hint--bounce:before,.hint--bounce:after {
  -webkit-transition: opacity .3s ease,visibility .3s ease,-webkit-transform .3s cubic-bezier(.71,1.7,.77,1.24);
  transition: opacity .3s ease,visibility .3s ease,transform .3s cubic-bezier(.71,1.7,.77,1.24)
}

.hint--html.hint:after {
  display: none;
  opacity: 0
}

.hint__label {
  font-size: 11px;
  line-height: 21px;
}

.hint__content {
  background: #383838;
  color: #fff;
  padding: 8px 10px;
  font-size: 12px;
  line-height: 12px
}

.hint .hint__content,[data-hint] .hint__content {
  position: absolute;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  -webkit-transition: .3s ease;
  transition: .3s ease;
  -webkit-transition-delay: 0;
  transition-delay: 0
}

.hint:hover .hint__content,.hint:focus .hint__content,[data-hint]:hover .hint__content,[data-hint]:focus .hint__content {
  visibility: visible;
  opacity: 1
}

.hint:hover .hint__content,[data-hint]:hover .hint__content {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms
}

.hint.hint--notrans:before,.hint.hint--notrans:after,[data-hint].hint--notrans:before,[data-hint].hint--notrans:after {
  -webkit-transition: none;
  transition: none
}

.hint.hint--notrans .hint__content,[data-hint].hint--notrans .hint__content {
  -webkit-transition: none;
  transition: none
}

.hint--top:before,.hint--top:after {
  left: calc(50% - 6px)
}

.hint--top .hint__content {
  margin-left: -18px;
  bottom: 100%;
  left: calc(50% - 6px)
}

.hint--top .hint__content:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red
}

.hint--top:hover .hint__content,.hint--top:focus .hint__content {
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px)
}

.hint--bottom:before,.hint--bottom:after {
  left: calc(50% - 6px)
}

.hint--bottom .hint__content {
  margin-left: -18px;
  top: 100%;
  left: calc(50% - 6px)
}

.hint--bottom .hint__content:after {
  content: ' ';
  position: absolute;
  top: -12px;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red
}

.hint--bottom:hover .hint__content,.hint--bottom:focus .hint__content {
  -webkit-transform: translateY(8px);
  -ms-transform: translateY(8px);
  transform: translateY(8px)
}

.hint--right .hint__content {
  margin-bottom: -14px;
  left: 100%;
  bottom: 50%
}

.hint--right .hint__content:after {
  content: ' ';
  position: absolute;
  left: -12px;
  top: 0;
  height: 100%;
  width: 12px;
  opacity: 0;
  background-color: red
}

.hint--right:hover .hint__content,.hint--right:focus .hint__content {
  -webkit-transform: translateX(8px);
  -ms-transform: translateX(8px);
  transform: translateX(8px)
}

.hint--left .hint__content {
  margin-bottom: -14px;
  right: 100%;
  bottom: 50%
}

.hint--left .hint__content:after {
  content: ' ';
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 12px;
  opacity: 0;
  background-color: red
}

.hint--left:hover .hint__content,.hint--left:focus .hint__content {
  -webkit-transform: translateX(-8px);
  -ms-transform: translateX(-8px);
  transform: translateX(-8px)
}

.hint--error .hint__content {
  background-color: #b34e4d
}

.hint--error.hint--top:before {
  border-top-color: #b34e4d
}

.hint--error.hint--bottom:before {
  border-bottom-color: #b34e4d
}

.hint--error.hint--left:before {
  border-left-color: #b34e4d
}

.hint--error.hint--right:before {
  border-right-color: #b34e4d
}

.hint--warning .hint__content {
  background-color: #c09854
}

.hint--warning.hint--top:before {
  border-top-color: #c09854
}

.hint--warning.hint--bottom:before {
  border-bottom-color: #c09854
}

.hint--warning.hint--left:before {
  border-left-color: #c09854
}

.hint--warning.hint--right:before {
  border-right-color: #c09854
}

.hint--info .hint__content {
  background-color: #3986ac
}

.hint--info.hint--top:before {
  border-top-color: #3986ac
}

.hint--info.hint--bottom:before {
  border-bottom-color: #3986ac
}

.hint--info.hint--left:before {
  border-left-color: #3986ac
}

.hint--info.hint--right:before {
  border-right-color: #3986ac
}

.hint--success .hint__content {
  background-color: #458746
}

.hint--success.hint--top:before {
  border-top-color: #458746
}

.hint--success.hint--bottom:before {
  border-bottom-color: #458746
}

.hint--success.hint--left:before {
  border-left-color: #458746
}

.hint--success.hint--right:before {
  border-right-color: #458746
}

.hint.hint--hidden:hover:after,.hint.hint--hidden:hover:before {
  opacity: 0;
  visibility: hidden
}

.hint.hint--hidden:hover .hint__content {
  opacity: 0;
  visibility: hidden
}

.hint--always .hint__content {
  opacity: 1;
  visibility: visible
}

.hint--always.hint--top .hint__content {
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px)
}

.hint--always.hint--bottom .hint__content {
  -webkit-transform: translateY(8px);
  -ms-transform: translateY(8px);
  transform: translateY(8px)
}

.hint--always.hint--left .hint__content {
  -webkit-transform: translateX(-8px);
  -ms-transform: translateX(-8px);
  transform: translateX(-8px)
}

.hint--always.hint--right .hint__content {
  -webkit-transform: translateX(8px);
  -ms-transform: translateX(8px);
  transform: translateX(8px)
}
