.react-autosuggest__container * {
  box-sizing: border-box;
}

.react-autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  border: 1px solid #d5dce6;
  border-radius: 4px;
  box-shadow: inset 0 2px 0 0 #f2f5f8;
  font-weight: 300;
  font-size: 16px;
  min-width: 200px;
  height: 38px;
  padding: 6px 12px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 37px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 15px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.autosuggest__item {
  display: flex;
  flex-direction: column;
  cursor: inherit;
}

.autosuggest__item-field {
  color: #666;
  font-size: 14px;
  display: block;
  width: 100%;
}

.seaching-spiner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
