.oto-date-range-picker {
  display: flex;
  flex-flow: column;

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrDateRangePickerWrapper {
    display: inline-flex;
    flex-flow: column nowrap;
  }

  .rdrDefinedRangesWrapper {
    background: transparent;
    display: flex;
    flex-flow: column;
    width: 100%;
  }

  .rdrInputRanges {
    padding: 0;
  }

  .rdrStaticRanges {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .rdrCalendarWrapper {
    display: flex;
    align-self: flex-start;
  }

  .rdrStaticRange {
    background: var(--white);
    border: 1px solid var(--primary);
    border-radius: 20px;
    overflow: hidden;
    margin: 2px 5px;
    &.rdrStaticRangeSelected, &:hover, &:active {
      background: var(--primary);
      color: var(--white) !important;
    }
  }

  

  .rdrStaticRanges {
    flex-wrap: wrap;
  }

  .rdrStaticRangeLabel {
    white-space: nowrap;
  }

  .rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
    background: transparent;
  }
}
