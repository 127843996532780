.calendar-preferences {
  display: flex;
  align-items: center;
  gap: 10px;

  &__item {
    font-size: 80%;
    font-weight: 400;
    &:after {
      content: '|';
      margin: 0 5px 0 10px;
    }

    &:last-child:after {
      content: '';
    }
  }
}
