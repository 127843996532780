@import '../../../scss/components/vars.scss';
@import '../../../scss/components/mixins.scss';
@import '../../../scss/components/utilities.scss';

@mixin strikethrough($color: black, $angle: -5deg) {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid $color;
    border-color: inherit;
    transform: rotate($angle);
  }
}

.add-new-product {
  margin-left: 0.5rem;
  max-width: 180px;
  text-align: left;
}

.products-menu-navbar {
  background: white;
  border-radius: 5px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  position: sticky;
  top: 50px;
  z-index: 5;

  @include md {
    padding: 10px;
    top: 0;
  }
}
