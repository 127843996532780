.driver-deliveries-buttons-wrapper {
  margin-left: 0;
  margin-right: 0;

  button {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
