.report-bug {
  border: 1px solid red;
  border-radius: 20px;
  color: red;
  display: inline-flex;
  padding: 6px 9px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;

  &:hover,
  &:focus,
  &:active {
    color: red;
  }
}
