@import '../../../../scss/components/vars.scss';
@import '../../../../scss/components/mixins.scss';

.restaurant-map {
  margin: 15px 0;

  &__place {
    background-color: yellow;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: 2px solid black;
    margin: 15px 0;
    position: relative;
    height: auto;
    overflow: scroll;
    @include lg {
      overflow: hidden;
    }
  }

  &__place-table {
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-size: 0.9rem;
    position: absolute;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &--read-only {
      cursor: default;
    }
    
    &--free {
      background-color: rgba(0, 200, 0, 0.6);
    }
    
    &--inactive {
      background-color: black;
    }

    &--proposed {
      background-color: rgba(255, 255, 0, 0.6);
      color: black;
    }
    
    &--busy {
      background-color: rgba(255, 0, 0, 0.7);
    }

    &--selected {
      background-color: rgba(0, 0, 255, 0.6);
    }
  }
}
