@import '../../../../scss/components/vars.scss';

.fc-event {
  cursor: pointer;
}

.fc-day-grid-event .fc-content {
  cursor: pointer;
  white-space: normal;
}

.fc-h-event {
  padding: 3px 2px;
  margin-bottom: 5px;
}

.fc-daygrid-day-number {
  border-radius: 50%;
  cursor: pointer;
  min-width: 30px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  &:hover {
    background: red;
    color: white;
    text-decoration: none;
  }
}

.fc-row tbody > tr:last-child td .fc-event {
  margin-bottom: 40px;
}

@media (max-width: $breakPointSm) {
  .fc-view {
    overflow-x: scroll;
    & > table {
      min-width: 500px;
    }
  }
  .fc-toolbar {
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (max-width: $breakPointMd) {
  .fc-toolbar {
    align-items: flex-end;
    gap: 10px;
  }

  .fc-toolbar-chunk:last-child {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 10px;
  }
}

.fc-resourceTimelineDay-view .fc-timeline-event .fc-title {
  white-space: normal;
}

.fc-license-message {
  opacity: 0;
}

.calendar-slot-busy {
  background-color: rgba(255, 0, 0, 0.75);
  color: white;
}

.reservation-note {
  color: red;
}

.fc-event-main-inner {
  padding: 0.25rem;
  position: relative;
  height: 100%;

  &[data-notes]:after {
    position: absolute;
    left: 0;
    top: 0;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 4px;
    white-space: pre-wrap;
    max-width: 100%;
  }

  &[data-notes]:hover:after {
    content: attr(data-notes);
  }
}

.fc-v-event .fc-event-main-inner {
  flex-flow: row wrap;
  padding: 0;
}

.fc-daygrid-day {
  .fc-event-main-inner {
    flex-wrap: wrap;
  }

  .fc-event-title {
    white-space: pre-wrap;
  }
}

@media print {
  .fc-toolbar .fc-button,
  .fc-toolbar .fc-button-group {
    display: none;
  }
}
