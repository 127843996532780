@import '../../../scss/components/vars.scss';
@import '../../../scss/components/mixins.scss';

$yellow: #ffc;
$purple: #ccf;
$green: #cfc;
$red: #fc9f97;

.order {
  background: white;
  color: black;
  cursor: pointer;
  margin: 5px 15px 30px;
  padding: 8px 12px;

  &__title {
    border-bottom: 1px solid black;
    padding-bottom: 10px;
  }

  &__body {
    padding: 5px 5px 10px;
    &:empty {
      padding: 0;
    }
  }

  &__btn {
    border-color: black !important;
    border-radius: 0 !important;
    padding: 6px 8px !important;
    margin-top: 8px;
    transition: all 0.5s ease;

    &:hover {
      background-color: black;
      color: white !important;
    }
  }
}

.order-sticky-note {
  transform: rotate(-4deg);
  flex: 1;
  flex-basis: calc(50% - 30px);
  min-width: 250px;
  max-width: 420px;

  @include md {
    flex-basis: calc(33% - 30px);
  }

  &:nth-child(2n) {
    transform: rotate(2deg);
  }

  &:nth-child(3n) {
    transform: rotate(-1deg);
  }

  &:nth-child(3n) {
    transform: rotate(-3deg);
  }

  &--confirmed {
    background: $yellow;
  }

  &--declined {
    background: $red;
  }

  &--sent {
    background: $purple;
  }

  &--delivered {
    background: $green;
  }

  .order__status {
    border-top: 1px solid black;
    padding-top: 3px;
  }
}

.order-list-item {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 6px solid;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-flow: row wrap;
  margin: 0 5px 5px;
  max-width: 100%;
  width: 100%;

  &--confirmed {
    border-left-color: $yellow;
  }

  &--declined {
    border-left-color: $red;
  }

  &--sent {
    border-left-color: $purple;
  }

  &--delivered {
    border-left-color: $green;
  }

  .order__col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    &--details {
      order: 2;
      & > div {
        margin-bottom: 6px;
        &:empty,
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .order__time {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    max-width: 150px;
    &-value {
      font-size: 1.35rem;
    }
  }

  .order__col,
  .order__time {
    border-right: 1px solid #ccc;
    padding: 10px;
    &:first-child {
      padding-left: 0;
    }
    // actually last, since order: 2 is used
    &:nth-child(2) {
      border-right: 0;
      margin-right: 0;
      padding-right: 0;
    }
  }

  .order-title {
    padding-bottom: 0;
  }
}


