.print-only {
  display: none;
}

@media print {
  .no-print {
    display: none;
  }
  .print-only {
    display: block;
  }
  .app-header,
  .app-sidebar {
    display: none;
  }
  #page-content {
    margin-left: 0;
  }
}
