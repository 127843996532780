/* WIDTH UTILITY STYLES
   ----------------------------- */

.w-full {
  width: 100%;
}
.w-8 {
  width: 8px;
  height: 8px;
  display: inline-block;
}

.w-16 {
  width: 16px;
  height: 16px;
  display: inline-block;
}

.w-20 {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.w-24 {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
}

.w-32 {
  width: 32px;
  height: 32px;
  display: inline-block;
  text-align: center;
}

.w-40 {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
}

.w-48 {
  width: 48px;
  height: 48px;
  display: inline-block;
  text-align: center;
}

.w-56 {
  width: 56px;
  height: 56px;
  display: inline-block;
  text-align: center;
}
.w-100 {
  width: 100px;
  display: inline-block;
  text-align: center;
}

.mw-70 {
  max-width: 70px;
}
.mw-125 {
  max-width: 125px;
}
.mw-150 {
  max-width: 150px;
}
.mw-175 {
  max-width: 175px;
}
.mw-200 {
  max-width: 200px;
}
.mw-250 {
  max-width: 250px;
}
.mw-300 {
  max-width: 300px;
}
.mw-400 {
  max-width: 400px;
}
.mw-500 {
  max-width: 500px;
}
.mw-600 {
  max-width: 600px;
}

.mw-800 {
  max-width: 800px;
}

.min-w-150 {
  min-width: 150px;
}
.min-w-175 {
  min-width: 175px;
}
.min-w-200 {
  min-width: 200px;
}
.min-w-250 {
  min-width: 250px;
}
.min-w-300 {
  min-width: 300px;
}
.min-w-400 {
  min-width: 400px;
}

/* HEIGHT UTILITY STYLES
   ----------------------------- */

.mh-50 {
  max-height: 50px;
}
