@import '../../scss/components/vars.scss';
@import '../../scss/components/mixins.scss';

.orders-container {
  padding: 10px;

  @include md {
    padding: 0;
  }
}
