/* UTILITY STYLES
   ----------------------------- */

.clear {
  display: block;
  overflow: hidden;
}
.full-bleed {
  margin: -$panelPadding;
}

hr.delivery-divider {
  border-color: #ccc;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.strong {
  font-weight: bold;
}

.fs-20 {
  font-size: 20px;
}

.centrify {
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.center-children {
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.centered-column {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.align-vertical {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.empty-m-0:empty {
  margin: 0 !important;
}

/* BORDERS */
.bt-1 {
  border-top: 1px solid black;
  padding-top: 3px;
}

.bb-1 {
  border-bottom: 1px solid black;
  padding-bottom: 3px;
}

.bc-grey {
  border-color: lightgrey;
}

.bt-grey-1 {
  border-top: 1px solid lightgrey;
  padding-top: 3px;
}

.b-danger {
  border: 2px solid $colorDanger !important;
}

.delimeter-top {
  border-top: 1px solid black;
  padding-top: 5px;
}

.br-1 {
  border-right: 1px solid grey;
}

.bg-transparent {
  background: transparent;
}

.bg-dimmed {
  background-color: rgba(0, 0, 0, 0.2);
}

/* TRANSFORM */

.rotate {
  translate: transform 0.5s ease;
  &--180 {
    transform: rotate(180deg);
  }
}

/* OTHER */
.clickable {
  cursor: pointer;
}

.grayscale {
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0);
  }
}

.nowrap {
  white-space: nowrap;
}
