@import '../../../../scss/components/vars.scss';
@import '../../../../scss/components/mixins.scss';

.delivery-status {
  border: 1px solid black;
  display: inline-flex;
  border-radius: 50%;
  min-height: 60px;
  min-width: 60px;
  @include sm {
    min-height: 80px;
    min-width: 80px;
  }
}
