@import '../../../scss/components/vars.scss';
@import '../../../scss/components/mixins.scss';

.new-order-modal {
    @include modal-wide();
  }
  
  .sm-minus {
    @include sm {
      display: none;
    }
  }