.autocomplete-dropdown-container {
    border: 1px solid black;
    border-radius: 5px;
    &:empty {
        border: 0;
    }
    div {
        padding: 3px 5px;;
    }
}
