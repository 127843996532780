.table-settings {
  &__form {
    overflow-x: scroll;
  }

  &__row {
    border-bottom: 1px solid #ccc;
    display: flex;
    min-width: 700px;

    &:last-of-type {
      border-bottom: 0px;
      margin-bottom: 40px;
    }

    &--heading {
      border-bottom: 0px;
      font-weight: bold;
      font-size: 1.05em;
    }

    &-cell {
      flex: 1;
      text-align: center;
      margin: 0 3px 2px;
    }
  }
}

.table-name-cell {
  max-width: 150px;
}

.table-size-cell {
  max-width: 100px;
}

.reduced-padding-table {
  td {
    padding: 10px !important;
  }
}
