@import "../../../scss/components/vars.scss";
@import "../../../scss/components/mixins.scss";

.product-edit-modal {
  @include modal-wide();

  .modal-body > .form-control {
    margin-bottom: 0.5rem;
  }
}

.product-options-row {
  border-bottom: 1px solid grey;
  padding-bottom: 10px;
  margin: 0 0 15px !important;
  & > .form-control, & > .input-group {
    flex: 2;
    margin-right: 6px;
    flex-basis: 100%;
  }
  & > .input-group {
    flex: auto;
    flex-grow: 0;
    width: auto;
  }
  @include sm {
    border-bottom: 0;
    padding-bottom: 0;
    .form-control {
      margin-bottom: 0px;
      min-width: auto;
    }
  }
}

.fileContainer {
  padding: 10px 0 0;
  
  .uploadPictureContainer {
    margin: 10px;
  }
}
