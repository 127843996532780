@mixin event-color($bg, $color: white) {
  background: $bg !important;
  border-color: $bg !important;
  color: $color !important;
  .fc-event-main {
    color: $color !important;
  }
}

$note-indicator-size: 15px;

.event-label {
  height: $note-indicator-size;
  width: $note-indicator-size;
  min-width: $note-indicator-size;
  margin-right: 3px;
}

.event-label--file {
  color: white;
  background: #00b859;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  padding: 2px 8px;
  line-height: 1;
  width: auto;
  min-width: 36px;
}

.event-label--automatic {
  display: inline-block;
  border-radius: 50%;
  background-color: white;
  background-image: url(https://cdn.otostolik.pl/img/transparent-192.png);
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
  // margin-right: 5px;
  // transform: translateY(2px);
}

.event-label--notes-from-restaurant {
  color: black;
  background: #ffd401;
  font-size: 11px;
  // margin-left: 3px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  // transform: translateY(-2px);
}

.event-label--notes-from-customer {
  color: white;
  background: #d22e01;
  font-size: 11px;
  // margin-left: 3px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  // transform: translateY(-2px);
}

.fc-event--auto-confirmed {
  .fc-title {
    &:before {
      content: '';
      @extend .event-label--automatic;
    }
  }
}

.fc-event-declined {
  @include event-color(#e84304);
  &:hover,
  &:focus {
    @include event-color(#e84304);
  }
}

.fc-event-pending,
.fc-event-reservation-proposal {
  // @include event-color(#ffd401, black);
  // &:hover,
  // &:focus {
  // @include event-color(#ffd401, black);
  // }
}

.fc-event-success {
  @include event-color(#308a30);
  &:hover,
  &:focus {
    @include event-color(#308a30);
  }
}

.fc-event {
  [class$='event-dot'] {
    display: none;
  }
}

.fc-event--no-time {
  .fc-event-time {
    display: none;
  }
}

.fc-event-note {
  @extend .fc-event-pending;
  @extend .fc-event--no-time;
}

.fc-event-count-status {
  cursor: default;
  @extend .fc-event-success;
  @extend .fc-event--no-time;
  padding-left: 4px;
  padding-right: 4px;
  &:hover,
  &:focus {
    @extend .fc-event-success;
  }
}

.fc-event--with-note {
  &.fc-timeline-event:before,
  .fc-time:after {
    content: '!';
    @extend .event-label--notes-from-restaurant;
  }
  &.fc-timeline-event:before {
    height: $note-indicator-size;
    margin-top: 4px;
  }
}

.fc-event--with-attachment {
  align-items: center;
  &.fc-timeline-event:before,
  .fc-time:after {
    content: 'Plik';
    @extend .event-label--file;
  }
  &.fc-timeline-event:before {
    height: $note-indicator-size;
    margin-top: 4px;
  }
}

.fc-resource-area .fc-rows tr td div,
.fc-time-area .fc-rows tr td div {
  height: 30px !important;
}

.fc-body .fc-resource-area .fc-cell-content {
  padding-top: 0;
  padding-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.75rem;
}

.fc-left {
  .fc-button.fc-button-primary {
    margin-left: 3px;
    margin-top: 5px;
  }
}
