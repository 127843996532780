/* BUTTON STYLES
   ----------------------------- */
.btn {
	border-radius: $btnBorderRadius;
	transition: all $btnTransitionSpeed ease;

	&.btn-primary {
		background-color: $btnColorPrimary;
		@include buttonBorder($btnColorPrimary);

		&:focus,
	    &:hover {
	    	background-color: darken($btnColorPrimary, 10%);
	    	@include buttonBorder(darken($btnColorPrimary, 10%));
	    }
	}
	
	&.btn-secondary {
		@include defaultButtonStyle;
	}

	&.btn-success {
		color: #fff;
		background-color: $btnColorSuccess;
		@include buttonBorder($btnColorSuccess);

		&:focus,
	    &:hover {
	    	background-color: darken($btnColorSuccess, 10%);
	    	@include buttonBorder(darken($btnColorSuccess, 10%));
	    }
	}

	&.btn-danger {
		color: #fff;
		background-color: $btnColorDanger;
		@include buttonBorder($btnColorDanger);

		&:focus,
	    &:hover {
	    	background-color: darken($btnColorDanger, 10%);
	    	@include buttonBorder(darken($btnColorDanger, 10%));
	    }
	}

	&.btn-info {
		color: #fff;
		background-color: $btnColorInfo;
		@include buttonBorder($btnColorInfo);

		&:focus,
	    &:hover {
	    	background-color: darken($btnColorInfo, 10%);
	    	@include buttonBorder(darken($btnColorInfo, 10%));
	    }
	}

	&.btn-warning {
		color: #fff;
		background-color: $btnColorWarning;
		@include buttonBorder($btnColorWarning);

		&:focus,
	    &:hover {
	    	background-color: darken($btnColorWarning, 10%);
	    	@include buttonBorder(darken($btnColorWarning, 10%));
		}
		
		&:active {
			color: #fff!important;
		}
	}

	&.btn-outline-primary {
		@include buttonBorder($btnColorPrimary);

		&:focus,
	    &:hover {
	    	@include buttonBorder($btnColorPrimary);
	    }
	}
	&.btn-outline-secondary {
		@include buttonBorder($btnColorSecondary);

		&:focus,
	    &:hover {
	    	@include defaultButtonStyle;
	    	@include buttonBorder(darken($btnColorSecondary, 10%));
	    }
	}
	&.btn-outline-success {
		@include buttonBorder($btnColorSuccess);

		&:focus,
	    &:hover {
	    	@include buttonBorder($btnColorSuccess);
	    }
	}
	&.btn-outline-info {
		@include buttonBorder($btnColorInfo);

		&:focus,
	    &:hover {
		@include buttonBorder($btnColorInfo);
		}
	}
	&.btn-outline-warning {
		@include buttonBorder($btnColorWarning);

		&:focus,
	    &:hover {
		@include buttonBorder($btnColorWarning);
		}
	}
	&.btn-outline-danger {
		@include buttonBorder($btnColorDanger);

		&:focus,
	    &:hover {
		@include buttonBorder($btnColorDanger);
		}
	}
}

.brand-btn {
  background: var(--primary);
  background: -moz-linear-gradient(left, var(--primary) 0%, var(--darkenPrimary) 100%);
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--darkenPrimary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--darkenPrimary) 100%);
  color: var(--white);
  display: inline-block;
  padding: 11px 24px;
  font-size: 14px;
  border-radius: 50px;
  border: none;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.brand-btn:focus,
.brand-btn:active,
.brand-btn:hover {
  color: var(--white);
  background: var(--darkenPrimary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-image: -moz-linear-gradient(22deg, #f34f22 0%, #ee3e0d 100%);
  background-image: -webkit-linear-gradient(22deg, #f34f22 0%, #ee3e0d 100%);
  background-image: -ms-linear-gradient(22deg, #f34f22 0%, #ee3e0d 100%);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12), 0px 8px 8px 0px rgba(0, 0, 0, 0.12);
}