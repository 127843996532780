/* LOGIN STYLES
   ----------------------------- */

.login-page-wrapper {
  @extend .centrify;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch !important;

  padding: 50px 20px;

  position: relative;
  z-index: 1;
  max-width: 100%;
  @media screen and (min-height: 600px) {
    // transform: translateY(-80px);
  }
}

.login-wrap {
  width: auto;
  max-width: 400px;
  display: block;
  margin-top: 30px;
  margin: auto;
}
