.tags-cloud {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 20px;
}

.tag {
  border-radius: 18px;
  background: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);
  cursor: pointer;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 4px 8px;
  margin: 5px;
  &--active {
    background: var(--primary);
    color: var(--light);
  }

  &--disabled {
    border-color: var(--disabled);
    color: var(--disabled);
    cursor: default;
  }

  &__remove {
    border: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    padding: 2px;
    outline: none;
    height: 1.2rem;
    width: 1.2rem;
    &:hover,
    &:focus {
      background: lightgrey;
      outline: none;
    }
  }
}
