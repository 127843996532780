.delivery-buttons {
  display: flex;
  align-items: center;
  &--list {
    margin-top: 15px;
  }
  &--grid {
    .delivery-button:nth-child(n + 1) {
      margin-top: 10px;
    }
  }
}

.delivery-button {
  margin-right: 12px;
  max-width: 190px;
}
